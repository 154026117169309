<template>
    <div>
        <ts-page-title
            :title="$t('globalDriverFee.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('globalDriverFee.pageTitle'),
                    href: '/admin/finance/transportation-fees'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <table class="tw-w-full tw-mb-5">
                        <thead>
                            <tr>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2 tw-w-4"
                                >
                                    {{ $t('globalDriverFee.no') }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{ $t('globalDriverFee.numPackageFrom') }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{ $t('globalDriverFee.numPackageTo') }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{
                                        $t(
                                            'globalDriverFee.expressVipDeliveryFee'
                                        )
                                    }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{
                                        $t(
                                            'globalDriverFee.expressNormalDeliveryFee'
                                        )
                                    }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{
                                        $t(
                                            'globalDriverFee.standardVipDeliveryFee'
                                        )
                                    }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{
                                        $t(
                                            'globalDriverFee.standardNormalDeliveryFee'
                                        )
                                    }}
                                </th>
                                <th
                                    class="tw-w-8 tw-py-2 tw-px-1 tw-bg-gray-100"
                                >
                                    <a
                                        @click.prevent="addRow"
                                        href="#"
                                        class="tw-bg-blue-400 tw-px-2 tw-py-1 tw-rounded-full tw-text-white"
                                        ><i class="fa fa-plus"></i
                                    ></a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(l, index) in model.item_list"
                                :key="index"
                                class="tw-border-b"
                            >
                                <td class="tw-px-3 tw-py-2 tw-text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <input
                                        v-model.number="l.num_package_from"
                                        type="number"
                                        step="any"
                                        class="form-control"
                                        :placeholder="
                                            $t('globalDriverFee.numPackageFrom')
                                        "
                                    />
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.num_package_from'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.num_package_from'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <input
                                        v-model.number="l.num_package_to"
                                        type="number"
                                        step="any"
                                        class="form-control"
                                        :placeholder="
                                            $t('globalDriverFee.numPackageTo')
                                        "
                                    />
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.num_package_to'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.num_package_to'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <div class="tw-flex">
                                        <input
                                            type="number"
                                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'globalDriverFee.expressVipDeliveryFee'
                                                )
                                            "
                                            v-model.number="
                                                l.express_vip_delivery_fee
                                            "
                                        />
                                        <select
                                            v-model="
                                                l.express_vip_delivery_fee_type
                                            "
                                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                                        >
                                            <option value="$">$</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.express_vip_delivery_fee'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.express_vip_delivery_fee'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <div class="tw-flex">
                                        <input
                                            type="number"
                                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'globalDriverFee.expressNormalDeliveryFee'
                                                )
                                            "
                                            v-model.number="
                                                l.express_normal_delivery_fee
                                            "
                                        />
                                        <select
                                            v-model="
                                                l.express_normal_delivery_fee_type
                                            "
                                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                                        >
                                            <option value="$">$</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.express_normal_delivery_fee'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.express_normal_delivery_fee'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <div class="tw-flex">
                                        <input
                                            type="number"
                                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'globalDriverFee.standardVipDeliveryFee'
                                                )
                                            "
                                            v-model.number="
                                                l.standard_vip_delivery_fee
                                            "
                                        />
                                        <select
                                            v-model="
                                                l.standard_vip_delivery_fee_type
                                            "
                                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                                        >
                                            <option value="$">$</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.standard_vip_delivery_fee'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.standard_vip_delivery_fee'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <div class="tw-flex">
                                        <input
                                            type="number"
                                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'globalDriverFee.standardNormalDeliveryFee'
                                                )
                                            "
                                            v-model.number="
                                                l.standard_normal_delivery_fee
                                            "
                                        />
                                        <select
                                            v-model="
                                                l.standard_normal_delivery_fee_type
                                            "
                                            class="tw-w-14 tw-border tw-border-l-0 tw-rounded-r tw-outline-none tw-text-center"
                                        >
                                            <option value="$">$</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.standard_normal_delivery_fee'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.standard_normal_delivery_fee'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td
                                    class="tw-w-4 tw-px-3 tw-py-2 tw-text-center"
                                >
                                    <a
                                        href="#"
                                        class="tw-text-red-600"
                                        @click.prevent="removeRow(index)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div
                            class="col-md-12 tw-flex tw-justify-end tw-space-x-3"
                        >
                            <ts-button
                                @click.prevent="
                                    $router.push({
                                        name: 'global-driver-fee'
                                    })
                                "
                                :disabled="loading"
                                >{{ $t('cancel') }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                outline
                                @click.prevent="onSaveAddNew"
                                :disabled="loading"
                                >{{ $t('saveAddNew') }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                @click.prevent="onSave"
                                :disabled="loading"
                                >{{ $t('save') }}</ts-button
                            >
                        </div>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Errors } from 'form-backend-validation'

export default {
    name: 'transactionFee',
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
                item_list: []
            }
        }
    },
    computed: {},
    methods: {
        ...mapActions('finance/globalDriverFee', ['store']),
        addRow() {
            this.model.item_list.push({
                num_package_from: '',
                num_package_to: '',
                express_vip_delivery_fee: '',
                express_vip_delivery_fee_type: '$',
                express_normal_delivery_fee: '',
                express_normal_delivery_fee_type: '$',
                standard_vip_delivery_fee: '',
                standard_vip_delivery_fee_type: '$',
                standard_normal_delivery_fee: '',
                standard_normal_delivery_fee_type: '$'
            })
        },
        removeRow(index) {
            this.model.item_list.splice(index, 1)
        },
        onSaveAddNew() {
            this.errors = new Errors()
            this.loading = true
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                    this.clearInput()
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSave() {
            this.loading = true
            this.errors = new Errors()
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                    this.$router.push({ name: 'global-driver-fee' })
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        clearInput() {
            this.model.item_list = []
            this.addRow()
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.addRow()
        })
    }
}
</script>

<style lang="scss" scoped></style>
